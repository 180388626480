import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay,Navigation } from "swiper";

import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import 'swiper/css/navigation';
import { useNavigate } from "react-router-dom";
import LoadingBanner from "./LoadingBanner";
import SwiperItem from "./SwiperItem";
import { useSelector } from "react-redux";

const HomeSlider = ({ data }) => {
    const navigate = useNavigate()

    const isBannerLoaded = useSelector((state)=>state?.bannerLoading?.value)
 console.log(isBannerLoaded,"isBannerLoaded");
 
  
  return (
    <div className="homeBannerContainer">
      {
        !isBannerLoaded&&
      <div className="loading">
        <LoadingBanner minHeight={"600px"}/>
      </div>
      }
      <Swiper
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        loop={true}
        navigation ={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
        modules={[EffectFade, Autoplay,Navigation]}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
              <SwiperItem data={item}/>
          </SwiperSlide>
        ))}
    </Swiper>
    </div>
  );
};

export default HomeSlider;
