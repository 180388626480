import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageUrlCheck } from "../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getBannerLoading } from "../../../../Redux/BannerLoading/BannerLoadingSlice";
import { Link } from "react-router-dom";
const SwiperItem = ({ data }) => {
  console.log(data,"data");
  
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const dispatch = useDispatch()
  const [imagURL, setImageURL] = useState(
    projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
      ? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
      : require("../../../../assets/Images/loading-landscape.gif")
  );
  const [isImageLoad, setIsImageLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    return ()=>{
      dispatch(
        getBannerLoading({
          bannerLoading:false
        })
      )
    }
  },[])

  const showClickHandler = (item) => {
    if (item?.show_id) {
      navigate(`/show-details/${item?.vanity_url ? item?.vanity_url : item?.show_id}`, {
        state: { showId: item?.show_id },
      });
    } else if (item?.event_id) {
      navigate(`/event/${item?.vanity_url ? item?.vanity_url : item?.event_id}`, {
        state: { showId: item?.event_id },
      });
    }
  };

  
  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE;
  };
  const afterImageLoad = (e) => {
    setIsImageLoad(true);
    dispatch(
      getBannerLoading({
        bannerLoading:true
      })
    )
  };
  return (
    <div className={!isImageLoad?"bannerContainer loading":"bannerContainer"}>
      <div className="left">
        <div className="contents">
          <h1 className="title">{data?.show_name}</h1>
          {data?.synopsis && <p className="description">{data?.synopsis}</p>}

          <div className="genresContainer">
          <span className="duration">{data?.duration_text}</span>
          </div>         

          <div className="buttonsWrapper">
            <div className="buttonContainer" onClick={() => showClickHandler(data)}>
              <div className="background"></div>
              <button>Watch Now</button>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="fadeGradient"></div>
        <div className={"imageContainer"}>
          {imageUrlCheck(data?.banner) ? (
            <img src={data?.banner} onError={(e) => replaceImage(e)} onLoad={(e) => afterImageLoad(e)} alt="BgImage" />
          ) : (
            <img src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner}`} onError={(e) => replaceImage(e)} alt="BgImage"  />
          )}
        </div>
      </div>
    </div>
  );
};

export default SwiperItem;
