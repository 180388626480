import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";

const CurrentPasswordModal = (props) => {
  const [input, setInput] = useState("");
 
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    
    
  }, []);

  const validationPasword = () => {
    const {currentPassword} = props?.values
    let validationStatus =  true;
    let errors = {}
    if(!currentPassword){
        errors.currentPassword="Current password is required!"
        validationStatus = false
    }
    props?.setErrors(errors)
    return validationStatus
  }

const submitButton = (e) =>{
    e.preventDefault();
    if(validationPasword()===true){
        props?.updateAccountDetails()
    }
    
}

const closeHandler = () => {
  props.setPasswordModal(false)
}
  
  return (
    <div className="currentPasswordModal">
      <div className="overlayModal"></div>
      <div className="currentPasswordContainer">
        <div className="closePasswordContainer" onClick={closeHandler}><CloseIcon/></div>
        <label htmlFor="currentPassword">Enter your current password</label>
        <input
          type="password"
          name="currentPassword"
          id="currentPassword"
          value={props?.values?.currentPassword || ""}
          onChange={(item)=>props?.handleUpdate(item)}
        />
        {props?.errors?.currentPassword && <span className="error">{props?.errors?.currentPassword}</span>}
        <div className="buttonContainer" onClick={(e)=>submitButton(e)}>
          <div className="background"></div>
          <button>Update</button>
        </div>
      </div>
    </div>
  );
};

export default CurrentPasswordModal;
